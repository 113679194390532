import { useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { capitalize, identity } from 'lodash';
import PropTypes from 'prop-types';
import { Header, Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  fromConceptOntologyLabelFormatter,
  useConceptLabelFormatter,
  useConceptParentLabelFormatter,
} from 'reducers/entityLabelFormatter';

import ColoredDot from 'components/ui/ColoredDot';
import SentimentCell from 'components/ui/cells/SentimentCell';
import HelpcrunchHelpTooltip from 'components/ui/navigation/HelpcrunchHelpTooltip';
import { ReactTableVolumeCell } from 'components/ui/table/cells/ReactTableCell';

import commonPropTypes from 'utils/commonPropTypes';
import { contributionPercentFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

const ClickableTableRow = styled(Table.Row)`
  &&&&&& {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
    transition: ${svars.transitionBase};
    ${svars.hoverClickableCss}
  }
`;
const StyledHeaderCell = styled(Table.HeaderCell)`
  &&& {
    text-transform: capitalize;
    padding: ${svars.spaceNormal};
    font-size: ${svars.fontSizeSmall};
    font-weight: ${svars.fontWeightBase};
    vertical-align: bottom;
  }
`;

const getColor = (axis, i) => {
  const colors = (axis === 'top' && svars.TOP_COLORS) || svars.BOTTOM_COLORS;
  return colors[i] || colors[colors.length - 1];
};

const getContributionColor = (top_index) => {
  if (top_index === 0) {
    return svars.fontColorLighter;
  }
  if (top_index > 0) {
    return svars.absoluteMaxColor;
  }
  return svars.absoluteMinColor;
};

const StyledCell = styled(Table.Cell)`
  &&& {
    padding: 0.3rem 0.5rem;
  }
`;

function TopBottomTable({
  exportId,
  displayOption,
  conceptSelection,
  onLegendClick,
  axis,
  height,
}) {
  const ontologyLabelFormatter = useSelector(fromConceptOntologyLabelFormatter);

  const conceptLabelFormatter = useConceptLabelFormatter();
  const conceptParentLabelFormatter = useConceptParentLabelFormatter(
    identity,
    capitalize(i18n._(t({ id: 'concept-global' })))
  );
  const maxNChunks = Math.max(
    ...(conceptSelection?.[displayOption] || []).map(
      (selectedConcept) => selectedConcept.n_chunks
    )
  );
  return (
    <div style={{ height, overflowY: 'auto' }}>
      <div id={exportId} style={{ display: 'inline-block', width: '100%' }}>
        <Table
          basic="very"
          celled
          unstackable
          style={{ padding: svars.spaceSmall, margin: 'auto' }}
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <StyledHeaderCell />
              <StyledHeaderCell>
                <Trans id="volume" />
              </StyledHeaderCell>
              <StyledHeaderCell>
                <Trans id="sentiment" />
              </StyledHeaderCell>
              <StyledHeaderCell style={{ whiteSpace: 'nowrap' }}>
                <span data-html2canvas-ignore="true">
                  <HelpcrunchHelpTooltip
                    smallTooltip
                    style={{ paddingRight: svars.spaceMediumLarge, height: svars.fontSizeBase }}
                    position="top right"
                    helpText={t({
                      id: 'top-bottom-panel.contribution-description',
                    })}
                    articleId={3}
                    helpcrunchHelpTooltipTestId="bo-helpcrunch-article-3"
                    triggerText={<Trans id="contribution" />}
                  />
                </span>
              </StyledHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body data-testid="topbottomtable-body">
            {((conceptSelection && conceptSelection[displayOption]) || []).map(
              (selectedConcept, i) => {
                const concept = conceptLabelFormatter(
                  selectedConcept?.db_concept
                );

                const parentConcept = conceptParentLabelFormatter(
                  selectedConcept?.db_concept
                );

                const ontologies = ontologyLabelFormatter(
                  selectedConcept?.db_concept
                );

                const rowProps = onLegendClick
                  ? {
                      onClick: () => onLegendClick(selectedConcept?.db_concept),
                      clickable: 'true',
                    }
                  : {
                      clickable: null,
                    };
                return (
                  <ClickableTableRow
                    key={`topbottomtable-${selectedConcept?.db_concept}`}
                    data-testid="topbottomtable-row"
                    {...rowProps}
                  >
                    <StyledCell width={9}>
                      <Header
                        as="h5"
                        image
                        style={{
                          width: '100%',
                          fontWeight: svars.fontWeightSemiBold,
                        }}
                      >
                        <Header.Content style={{ width: 'inherit' }}>
                          <span
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>
                              <ColoredDot
                                style={{
                                  marginRight: svars.spaceNormalLarge,
                                }}
                                color={getColor(axis, i)}
                              />
                              {concept}
                              {onLegendClick ? (
                                <Icon
                                  data-html2canvas-ignore="true"
                                  style={{
                                    paddingLeft: svars.spaceMedium,
                                  }}
                                  name="chevron right"
                                />
                              ) : null}
                            </span>
                          </span>
                          <Header.Subheader
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              <Icon name="fork" /> {parentConcept || '-'}
                            </span>
                            {ontologies && ontologies.length > 1 && (
                              <span
                                style={{
                                  fontSize: svars.fontSizeSmall,
                                  textalign: 'end',
                                }}
                              >
                                (
                                {ontologyLabelFormatter(
                                  selectedConcept?.db_concept
                                )}
                                )
                              </span>
                            )}
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </StyledCell>
                    <StyledCell textAlign="center" width={2}>
                      <ReactTableVolumeCell
                        maxNChunks={maxNChunks}
                        value={selectedConcept.n_chunks}
                      />
                    </StyledCell>
                    <StyledCell textAlign="center" width={2}>
                      <SentimentCell
                        sentiment={selectedConcept.average_sentiment}
                      />
                    </StyledCell>
                    <StyledCell
                      style={{
                        color: getContributionColor(selectedConcept.top_index),
                      }}
                      textAlign="center"
                      width={3}
                    >
                      {contributionPercentFormatter(selectedConcept.top_index)}
                    </StyledCell>
                  </ClickableTableRow>
                );
              }
            )}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

TopBottomTable.propTypes = {
  exportId: PropTypes.string.isRequired,
  axis: PropTypes.oneOf(['top', 'bottom']).isRequired,
  conceptSelection: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        concept_id: PropTypes.string,
        top_index: PropTypes.number,
      })
    )
  ),
  displayOption: PropTypes.string.isRequired,
  ontologies: commonPropTypes.ontologies.isRequired,
  onLegendClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TopBottomTable.defaultProps = {
  conceptSelection: undefined,
  onLegendClick: undefined,
  height: svars.chartHeight,
};
export default TopBottomTable;
